import React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

export const BookADemo = ({ link, label }: { link: string; label: string }) => {
	const [isTreeRendered, setIsTreeRendered] = useState(false);

	useEffect(() => {
		if (isTreeRendered) {
			const script = document.createElement('script');
			script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
			script.async = true;
			script.onload = function () {
				//@ts-ignore
				calendar.schedulingButton.load({
					url: link,
					color: '#EF6C00',
					label: label,
					target: document.getElementsByClassName(`calendar-container-${label}`)[0],
				});
			};
			document.head.appendChild(script);
		}
	}, [isTreeRendered]);

	// Позначаємо, що дерево відрендерилось
	useEffect(() => {
		setIsTreeRendered(true);
	}, []);

	return (
		<SsBookADemoButton>
			<Helmet>
				<link
					href="https://calendar.google.com/calendar/scheduling-button-script.css"
					rel="stylesheet"
				/>
			</Helmet>
			<div className={`calendar-container-${label}`} />
		</SsBookADemoButton>
	);
};

export const SsBookADemoButton = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	button {
		min-height: 40px;
		color: ${({ theme, color }) => color || theme.colors.strongWhite};
		height: fit-content;
		width: fit-content;
		background: ${({ theme }) => `${theme.colors.wheat} !important`};
		cursor: pointer;
		border: ${({ theme }) => `2px solid ${theme.colors.darkText}`};
		padding: 7px 14px;
		box-sizing: border-box;
		border-radius: 40px;
		justify-content: center;
		font: ${({ theme }) => {
			return theme.fonts.button.small;
		}};
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.button.large};
			padding: 15px 30px;
		}
		transition: 0.2s linear;
		color: ${({ theme }) => theme.colors.darkText + ' !important'};
	}

	button:hover {
		color: ${({ theme }) => `${theme.colors.strongWhite} !important`};
		background: ${({ theme }) => `${theme.colors.darkText} !important`};
	}

	//@media (min-width: 1920px) {
	//    button {
	//        margin-top: 70px;
	//    }
	//}
`;
