import styled from 'styled-components';

export const ScNavigationAccordionItem = styled.details`
	width: 100%;
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.wheat};
	summary::-webkit-details-marker {
		display: none;
	}
	border-radius: 10px;
	width: 100%;
	height: fit-content;
	box-sizing: border-box;
	@media (min-width: 1220px) {
		border-radius: 30px;
	}
`;

export const ScNavigationAccordionItemTitle = styled.summary`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: ${({ theme }) => theme.colors.darkText};
	background: ${({ theme }) => theme.colors.wheat};
	padding: 16px;
	box-sizing: border-box;
	border-radius: 10px;
	font: ${({ theme }) => theme.fonts.accordion.small};
	width: 100%;
	height: fit-content;
	img {
		max-width: 35px;
		height: auto;
	}
	@media (min-width: 1220px) {
		font: ${({ theme }) => theme.fonts.accordion.enormous};
		padding: 40px;
		border-radius: 30px;
		svg {
			max-width: 35px;
		}
	}
	@media (min-width: 1920px) {
		font: ${({ theme }) => theme.fonts.accordion.large};
		padding: 40px;
		border-radius: 30px;
		svg {
			max-width: 35px;
		}
	}
`;

interface IScNavigationAccordionContent {
	columns: number;
}

export const ScNavigationAccordionContent = styled.div<IScNavigationAccordionContent>`
	padding: 0 16px 16px 16px;
	display: flex;
	flex-direction: column;
	gap: 5px;

	ol {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	@media (min-width: 1220px) {
		padding: 0 40px 40px 40px;
		display: grid;
		grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
		gap: 40px;
		* {
			font: ${({ theme }) => theme.fonts.accordion.largeText};
			gap: 12px !important;
		}
		> div {
			gap: 50px;
		}
		ol {
			gap: 40px;
		}
	}
`;
