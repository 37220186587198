import { useState } from 'react';
import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import loaf from '../../assets/images/bread-calculator.png';
import smallLoaf from '../../assets/images/bread-calculator-small-loaf-size.png';
import { validateInput } from '../../helpers/bike-calculations';
import useBikeCalculations from '../../hooks/use-bike-calculations';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScCalculatorAccordionWrapper } from '../../sections/EmployeeCycling/styled';
import formatNumberWithCommasAndCutDecimals from '../../utils/format/format-nuber-with-commas';
import { CalculatorAccordion } from '../CalculatorAccordion/CalculatorAccordion';
import { CalculatorSwitcher } from '../CalculatorSwitcher';
import { ScContainer } from '../container/styled';
import { Input } from '../Input';

import {
	ScCalculatorBlueberry,
	ScCalculatorBlueberryContent,
	ScCalculatorBrown,
	ScCalculatorCost,
	ScCalculatorInputContainer,
	ScCalculatorInputs,
	ScCalculatorInputsRow,
	ScCalculatorInputWrapper,
	ScCalculatorLabelWrapper,
	ScCalculatorLeft,
	ScCalculatorSavings,
	ScCalculatorText,
	ScCalculatorTitle,
	ScCalculatorWrapper,
} from './styled';

interface IBikesCalculator {
	isItEmployee: boolean;
	disableAccordions?: boolean;
}

export const BikesCalculator = ({ isItEmployee, disableAccordions }: IBikesCalculator) => {
	const [isToggled, setIsToggled] = useState(false);
	const [voucherValue, setVoucherValue] = useState('');
	const [salary, setSalary] = useState('');
	const theme = useTheme();
	const savings = useBikeCalculations(Number(voucherValue), Number(salary));
	const { ref, controls } = useContentAnimation();
	const { ref: accordionRef, controls: accordionControls } = useContentAnimation();

	const accordionsEmployee = [
		{
			title: 'Wait, how does that work?',
			columns: 2,
			text: [
				`<p>So, you earn <b>£${formatNumberWithCommasAndCutDecimals(
					salary,
				)}</b> gross income per year. And you want to buy a <b>£${formatNumberWithCommasAndCutDecimals(
					voucherValue,
				)}</b> bike.</p><p>Usually, you’d buy that with your net income, or take home pay, after you’ve paid tax and national insurance.</p>`,
				`<p>With Gogeta, you make that same <b>£${formatNumberWithCommasAndCutDecimals(
					voucherValue,
				)}</b> purchase before you’ve paid income tax and NI, with your gross salary. This simple change means your purchase actually only costs you <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.taxFreePrice) ? 0 : savings.taxFreePrice),
				)}</b> and so you save <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.totalSavings) ? 0 : savings.totalSavings),
				)}</b> on your bike.</p><p>You also get to pay the cost of your bike back over <b>12</b> months interest-free via salary sacrifice. Pretty good, right?</p>`,
			],
		},
		{
			title: 'What makes Gogeta different? ',
			columns: 2,
			text: [
				`<ol><li>Flexi-voucher - Unlike other cycle schemes who issue a voucher that you must redeem in full from one store, on one day, ours is flexible. So you can spend it as many times as you want, in different stores, until the balance runs out.</li><li>Gogeta is the only cycle to work scheme endorsed by bike retailers thanks to our low commission. This means they pass on the best deals to you and will never charge a surcharge for using Gogeta Bike.</li></ol>`,
				`<ol start="3"><li seq="3">No hidden fees at the end - by using the cycle to work scheme, you’re effectively hiring your bike over a set period of time before it becomes officially yours. To transfer ownership to you at the end we have to charge something (HMRC says so) but unlike other schemes who charge up to 10% of the value of your bike, we keep this as low as we possibly can - just <b>£1</b>. Don’t worry, this sounds complicated but it really isn’t. You can read more about it in our <a href="/employee-faq#bike-faqs" target="_blank">FAQs</a>.</li></ol>`,
			],
		},
		{
			title: 'How do I sign up? ',
			columns: 1,
			text: [
				`<p>To use Gogeta Bike your employer needs to sign up. It’s simple, quick and doesn’t cost them a penny. Follow the link at the bottom of this page to get going.</p>`,
			],
		},
	];

	const accordionsEmployer = [
		{
			title: 'Wait, how does that work?',
			columns: 2,
			text: [
				`<p>So, your employee earns <b>£${formatNumberWithCommasAndCutDecimals(
					salary,
				)}</b> gross income per year. And you want to buy a <b>£${formatNumberWithCommasAndCutDecimals(
					voucherValue,
				)}</b> bike.</p><p>Usually, you’d buy that with your net income, or take home pay, after you’ve paid tax and national insurance.</p>`,
				`<p>With Gogeta, you make that same <b>£${formatNumberWithCommasAndCutDecimals(
					voucherValue,
				)}</b> purchase before you’ve paid income tax and NI, with your gross salary. This simple change means their purchase actually only costs them <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.taxFreePrice) ? 0 : savings.taxFreePrice),
				)}</b> and so you save <b>£${formatNumberWithCommasAndCutDecimals(
					String(isNaN(savings.totalSavings) ? 0 : savings.totalSavings),
				)}</b> on your bike.</p><p>You also get to pay the cost of your bike back over <b>12</b> months interest-free via salary sacrifice. Pretty good, right?</p>`,
			],
		},
		{
			title: 'What makes Gogeta different?',
			columns: 2,
			text: [
				`<span>We designed Gogeta to be the easiest scheme for HR teams to deliver, to offer the best savings to employees and to treat retailers fairly. Here's how:</span><p><b>Less admin for you</b> - we know HR teams have a lot on their plate, so we made it as easy as possible to offer this benefit to staff. Once you apply, you'll get access to your own employer portal, which has everything yoj need to administer the scheme. Invite employees to apply, review applications, update payroll - all done in the portal.</p>`,
				`<p><b>Industry first Flexi-Voucher</b> - Unlike other cycle schemes , where employees need to spend their entire voucher in one shop, in one go, Gogeta has Flexi-Vouchers. This means your employees can buy a bike in spring and then gloves or lights in the autumn. All from the same voucher, all tax-free. And it works like a tax-free gift card, so no extra admin for you.</p><p><b>The best savings for employees</b> - Because we charge the lowest commissions to retailers out of any cycle to work provider, they pass on the best deals to your employees and will never charge a surcharge for using Gogeta Bike.</p>`,
			],
		},
		{
			title: 'How do I sign up? ',
			columns: 1,
			text: [
				`<p>Sign up online and start offering this benefit to staff today. Click <a href="/employer-cycling/plans/" target="_blank">here</a> to apply.</p>`,
			],
		},
	];

	const accordions = isItEmployee ? accordionsEmployee : accordionsEmployer;

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScCalculatorWrapper>
					<ScCalculatorLeft>
						<ScCalculatorInputs variants={fadeInRight} ref={ref}>
							<ScCalculatorInputWrapper>
								<ScCalculatorLabelWrapper>
									<ScCalculatorTitle>Take your dough</ScCalculatorTitle>
								</ScCalculatorLabelWrapper>
								<ScCalculatorInputContainer>
									<Input
										value={salary}
										background={theme.colors.white}
										changeHandler={(str) => {
											if (validateInput(str)) {
												setSalary(str);
											}
										}}
										placeholder="Enter your annual salary"
									/>
								</ScCalculatorInputContainer>
							</ScCalculatorInputWrapper>
							<ScCalculatorInputWrapper>
								<ScCalculatorLabelWrapper>
									<ScCalculatorTitle>How much is your bike voucher?</ScCalculatorTitle>
								</ScCalculatorLabelWrapper>
								<Input
									value={voucherValue}
									changeHandler={(str) => {
										if (validateInput(str)) {
											setVoucherValue(str);
										}
									}}
									background={theme.colors.white}
									placeholder="Enter your bike package cost"
								/>
							</ScCalculatorInputWrapper>
						</ScCalculatorInputs>
						<ScCalculatorBrown variants={fadeInRight}>
							{isToggled ? (
								<img src={smallLoaf as string} alt="bread" />
							) : (
								<img src={loaf as string} alt="small-loaf" />
							)}
						</ScCalculatorBrown>
					</ScCalculatorLeft>
					<ScCalculatorBlueberry variants={fadeInLeft} isActive={!isToggled}>
						<ScCalculatorBlueberryContent>
							<ScCalculatorCost>
								<ScCalculatorTitle isLarge>
									{isToggled ? 'Without' : 'With'} Gogeta, your bike costs
								</ScCalculatorTitle>
								<Input
									isLargeText
									isRounded
									background={isToggled ? theme.colors.darkText : theme.colors.strongWhite}
									color={isToggled ? theme.colors.strongWhite : theme.colors.darkText}
									value={
										salary === ''
											? 0
											: isToggled
											? voucherValue
											: isNaN(savings.taxFreePrice)
											? 0
											: Math.trunc(savings.taxFreePrice)
									}
									disabled
								/>
								<ScCalculatorSavings>
									Saving you{' '}
									{isToggled
										? 0
										: savings.totalSavingsPercentage > 0
										? savings.totalSavingsPercentage
										: 0}
									%
								</ScCalculatorSavings>
							</ScCalculatorCost>
							<ScCalculatorInputsRow>
								<ScCalculatorCost>
									<Input
										isRounded
										background={theme.colors.transparent}
										border={theme.colors.darkText}
										value={
											salary === ''
												? 0
												: isToggled
												? 0
												: isNaN(savings.monthlyTakeHomePay)
												? 0
												: Math.round(savings.monthlyTakeHomePay)
										}
										disabled
									/>
									<ScCalculatorText>
										{isToggled ? (
											<>
												No cost spread
												<br />
												over 12 months
											</>
										) : (
											'What it costs to you per month'
										)}
									</ScCalculatorText>
								</ScCalculatorCost>
								<ScCalculatorCost>
									<Input
										isRounded
										background={theme.colors.transparent}
										border={theme.colors.darkText}
										value={
											salary === ''
												? 0
												: isToggled
												? 0
												: isNaN(savings.totalSavings)
												? 0
												: Math.trunc(savings.totalSavings)
										}
										disabled
									/>
									<ScCalculatorText>
										{isToggled ? 'No total savings' : 'Your total savings'}
									</ScCalculatorText>
								</ScCalculatorCost>
							</ScCalculatorInputsRow>
							<CalculatorSwitcher isToggled={isToggled} setIsToggled={setIsToggled} />
						</ScCalculatorBlueberryContent>
					</ScCalculatorBlueberry>
				</ScCalculatorWrapper>
			</motion.section>
			{!disableAccordions && (
				<motion.section initial="hidden" animate={accordionControls} variants={animationContainer}>
					<ScCalculatorAccordionWrapper>
						{accordions.map((item) => (
							<MotionDiv key={item.title} variants={fadeInUp}>
								<CalculatorAccordion
									title={item.title}
									text={item.text}
									key={item.title}
									columns={item.columns}
								/>
							</MotionDiv>
						))}
					</ScCalculatorAccordionWrapper>
					<div ref={accordionRef} />
				</motion.section>
			)}
		</ScContainer>
	);
};
