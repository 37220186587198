import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import { BookADemo } from '../../components/BookADemo';
import ContactUsForm from '../../components/contact-us-form/ContactUsForm';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import Modal from '../../components/modal';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScReadyToStartSavingButtonsWrapper } from '../Home/styled';

import { ScBrownTextWrapper, ScBrownWrapper } from './styled';

interface IBrownInfo {
	title: string;
	text: string;
	image: string;
	link: string;
	isBike: boolean;
}

export const BrownInfo = ({ title, text, image, link, isBike }: IBrownInfo) => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();
	const [isOpenModal, setIsOpenModal] = useState(false);

	return (
		<>
      {isOpenModal &&
        <Modal open={isOpenModal} title={'Contact us'} handleClose={() => setIsOpenModal(false)}>
          <ContactUsForm />
        </Modal>
      }
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScContainer>
					<ScBrownWrapper>
						<ScBrownTextWrapper>
							<MotionDiv variants={fadeInRight}>
								<ScTitle isMobileLarge color={theme.colors.strongWhite} centered={!isDesktop}>
									{title}
								</ScTitle>
							</MotionDiv>
							<MotionDiv variants={fadeInRight}>
								<ScParagraph
									color={theme.colors.strongWhite}
									centered={!isDesktop}
									className="text"
								>
									{text}
								</ScParagraph>
							</MotionDiv>
							{!isDesktop && <MotionImage variants={fadeInUp} src={image} alt="bread image" />}
							<MotionDiv variants={fadeInRight}>
								<ScReadyToStartSavingButtonsWrapper ref={ref}>
									{isBike ? (
										<CustomButton
											color={theme.colors.darkText}
											background={theme.colors.strongWhite}
											onClick={() => setIsOpenModal(true)}
										>
											Let’s talk
										</CustomButton>
									) : (
										<BookADemo link={link} label={'Let’s talk'} />
									)}
								</ScReadyToStartSavingButtonsWrapper>
							</MotionDiv>
						</ScBrownTextWrapper>
						{isDesktop && <MotionImage variants={fadeInLeft} src={image} alt="bread image" />}
					</ScBrownWrapper>
				</ScContainer>
			</motion.section>
		</>
	);
};
