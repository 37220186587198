import { useState } from 'react';
import React from 'react';

import Minus from '../../assets/icons/minus.png';
import Plus from '../../assets/icons/plus.png';
import { ScParagraph } from '../Paragraph/styled';

import {
	ScNavigationAccordionContent,
	ScNavigationAccordionItem,
	ScNavigationAccordionItemTitle,
} from './styled';

export const CalculatorAccordion = ({
	title,
	text,
	columns,
}: {
	title: string;
	text: string[];
	columns: number;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<ScNavigationAccordionItem key={title}>
			<ScNavigationAccordionItemTitle onClick={() => setIsOpen(!isOpen)}>
				{title}
				{isOpen ? <img src={Minus} alt={'minus'}/> : <img src={Plus} alt={'Plus'}/>}
			</ScNavigationAccordionItemTitle>
			<ScNavigationAccordionContent columns={columns}>
				{text.map((item) => (
					<ScParagraph key={item} dangerouslySetInnerHTML={{ __html: item }} />
				))}
			</ScNavigationAccordionContent>
		</ScNavigationAccordionItem>
	);
};
