import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { isNotSSR } from '@utils';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInRight } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScSignUpTextWrapper, ScSignUpWrapper } from './styled';

export const SignUp = ({ isItBikeSite }: { isItBikeSite: boolean }) => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScSignUpWrapper>
					<ScSignUpTextWrapper>
						<MotionDiv variants={fadeInRight}>
							<ScTitle isMobileLarge color={theme.colors.strongWhite} centered>
								Sign up
							</ScTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInRight}>
							<ScParagraph color={theme.colors.strongWhite} centered className="text" ref={ref}>
								Get signed up and start helping your team’s salary go further now
							</ScParagraph>
						</MotionDiv>
						<MotionDiv variants={fadeInRight}>
							{isItBikeSite ? (
								<Link to={'/employer-cycling/plans/'}>
									<CustomButton color={theme.colors.darkText} background={theme.colors.strongWhite}>
										Let’s go
									</CustomButton>
								</Link>
							) : (
								isNotSSR && (
									<CustomButton
										color={theme.colors.darkText}
										background={theme.colors.strongWhite}
										onClick={() => {
											isNotSSR &&
												(window.location.href = `${process.env.GATSBY_NURSERY_WEBSITE}/portal/employer_portal/register`);
										}}
									>
										Let’s go
									</CustomButton>
								)
							)}
						</MotionDiv>
					</ScSignUpTextWrapper>
				</ScSignUpWrapper>
			</ScContainer>
		</motion.section>
	);
};
